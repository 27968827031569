@use "SASS:map";
@use "@angular/material" as mat;

@mixin color($theme, $isDarkTheme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, "primary");

  .infobar-container {
    @if $isDarkTheme {
      color: mat.m2-get-color-from-palette($primary-palette, 300) !important;
      background-color: mat.m2-get-color-from-palette($primary-palette, 800) !important;
    } @else {
      color: mat.m2-get-color-from-palette($primary-palette, 700) !important;
      background-color: mat.m2-get-color-from-palette($primary-palette, 100) !important;
    }
  }
}
