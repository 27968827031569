@use "SASS:map";
@use "@angular/material" as mat;

@mixin color($theme, $isDarkTheme) {
  $color-config: mat.m2-get-color-config($theme);
  $accent-palette: map.get($color-config, "accent");
  $primary-palette: map.get($color-config, "primary");

  @if $isDarkTheme {
    .column h5 {
      color: whitesmoke;
    }

    .column .category2 h5 {
      color: black !important;
    }

    .column .sum h5 {
      color: black !important;
    }
  }
}
