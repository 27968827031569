@use "SASS:map";
@use "@angular/material" as mat;

@mixin color($theme, $isDarkTheme) {
  $color-config: mat.m2-get-color-config($theme);
  $accent-palette: map.get($color-config, "accent");
  $primary-palette: map.get($color-config, "primary");

  .nav-disabled {
    color: mat.m2-get-color-from-palette($primary-palette, "darker") !important;
  }

  .nav-selected {
    color: mat.m2-get-color-from-palette($accent-palette) !important;
  }
}
