@use "sass:map";
@use "@angular/material" as mat;
@use "../themes/components";

$primaryPalette: mat.m2-define-palette(mat.$m2-gray-palette, 700, 600, 800);
$accentPalette: mat.m2-define-palette(mat.$m2-pink-palette, A100, A700, A100);
$warnPalette: mat.m2-define-palette(mat.$m2-red-palette);

$dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $primaryPalette,
      accent: $accentPalette,
      warn: $warnPalette,
    ),
    density: -2,
  )
);

/**
 * Beim Aktivieren des Dark-Themes über AppService, alle Components umfärben:
 * - Material Components
 * - Eigene Components
 */
.dark-theme {
  @include mat.all-component-colors($dark-theme);
  @include components.color($dark-theme, $isDarkTheme: true);
}
