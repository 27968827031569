/**
 * Themes wurden auf Basis von diesem Beispiel eingebaut: https://indepth.dev/tutorials/angular/angular-material-theming-system-complete-guide
 */
@use "@angular/material" as mat;
@use "./styles/themes/components";
@use "./styles/themes/light-theme" as light;
@use "./styles/themes/dark-theme" as dark;

@include mat.core();
@include mat.all-component-themes(light.$light-theme);
@include components.color(light.$light-theme, $isDarkTheme: false);

@import "material-icons/iconfont/material-icons.css";
@import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

/* roboto-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("assets/fonts/roboto-v30-latin-300.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/roboto-v30-latin-300.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/roboto-v30-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("assets/fonts/roboto-v30-latin-300.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/roboto-v30-latin-300.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("assets/fonts/roboto-v30-latin-300.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("assets/fonts/roboto-v30-latin-regular.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/roboto-v30-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/roboto-v30-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("assets/fonts/roboto-v30-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/roboto-v30-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("assets/fonts/roboto-v30-latin-regular.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("assets/fonts/roboto-v30-latin-500.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/roboto-v30-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/roboto-v30-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("assets/fonts/roboto-v30-latin-500.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/roboto-v30-latin-500.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("assets/fonts/roboto-v30-latin-500.svg#Roboto") format("svg"); /* Legacy iOS */
}

// Für die TaktischeZeichen-Bibliothek aus https://github.com/phjardas/taktische-zeichen/blob/main/packages/core/src/font.ts
@font-face {
  font-display: swap;
  src: url("assets/fonts/RobotoSlab-VariableFont_wght.ttf") format("truetype");
  font-family: "Roboto Slab";
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
}

// Entfernt Resize-Handle aus TextAreas unten rechts
textarea {
  resize: none !important;
}

// "Entfernt" den freien Platz unter einem Form-Field
// https://github.com/angular/components/issues/7975
mat-form-field.no-margin {
  margin-bottom: -1.25em;

  &.ng-invalid.ng-touched {
    margin-bottom: 0;
  }

  transition: margin-bottom 100ms ease-in-out;
}

// Reduziert den Platz unter einem Form-Field
// https://github.com/angular/components/issues/7975
mat-form-field.less-margin {
  margin-bottom: -0.6em;

  &.ng-invalid.ng-touched {
    margin-bottom: 0;
  }

  transition: margin-bottom 100ms ease-in-out;
}

.full-height {
  height: 100%;
}

.half-height {
  height: 50%;
}

.center-vertical {
  display: inline-block;
  vertical-align: middle;
}

.center-text {
  text-align: center;
}

// Flex settings
.flex-row {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-grow {
  flex: 1;
}

.gap-1 {
  gap: 1rem;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-gap-05 {
  gap: 0.5rem;
}

.flex-gap-1 {
  gap: 1rem;
}

.flex-gap-2 {
  gap: 2rem;
}

.flex-gap-3 {
  gap: 3rem;
}

.flex-split {
  justify-content: space-between;
}

.flex-content-end {
  justify-content: flex-end;
}

.flex-self-end {
  justify-self: flex-end;
}

/**
 *  Margins
 */
.m-05 {
  margin: 0.5rem;
}

.m-1 {
  margin: 1rem;
}

.m-2 {
  margin: 2rem;
}

.m-3 {
  margin: 3rem;
}

// right
.mr-05 {
  margin-right: 0.5rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mr-3 {
  margin-right: 3rem;
}

// bottom
.mb-05 {
  margin-bottom: 0.5rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

// left
.ml-05 {
  margin-left: 0.5rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.ml-3 {
  margin-left: 3rem;
}

// top
.mt-05 {
  margin-top: 0.5rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

/**
 * Paddings
 */

// Paddings
.p-05 {
  padding: 0.5rem;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 1rem;
}

.pt-1 {
  padding-top: 1rem;
}

// Opacities
.op-70 {
  opacity: 70%;
}

.title {
  font-size: 5rem;
}

.spacer {
  flex: 1 1 auto;
}

.list-row:hover {
  cursor: pointer;
}

.sub-toolbar {
  height: 3rem;
  min-height: 3rem;
  position: relative;
}

.sub-toolbar > h2 {
  margin: 0px;
}

@keyframes blink {
  100% {
    width: 100px;
    height: 100px;
    opacity: 0;
  }
}

// Versteckt weißes Viereck bei Leaflets L.divIcons
.hide-default-div {
  border: none;
}

.tool-text {
  font-size: 1rem;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

// Erzeugt Scrollbars, wenn Inhalt größer als Parent
.overflow-auto {
  overflow: auto;
}

// Farben zum Testen von Layouts
.red {
  background-color: red;
}

.blue {
  background-color: blue;
}

.green {
  background-color: green;
}

.purple {
  background-color: purple;
}

.yellow {
  background-color: yellow;
}

.font-warning {
  color: red;
  font-weight: 600;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  background-color: whitesmoke;
  transition: width 500ms ease-in-out;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: pink;
  transition: color 100ms ease-in-out;

  &:hover {
    background-color: #ff4081;
    cursor: pointer;
  }
}

/**
 * Variable Größe für Taktische Zeichen Popups
 */
.leaflet-popup-content {
  margin: 0.25rem;
  width: fit-content !important;
}

.icon-border {
  border: 2px solid black;
  border-radius: 50%;
}

.clean-dialog .mat-mdc-dialog-surface {
  padding: 0px;
  overflow: visible;
  background-color: rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;
}

.clean-dialog-backdrop {
  opacity: 0;
}

.bubble-menu-caller {
  outline: 2px solid #ff4081;
  border-radius: 0.25rem;
}

// Positioniert Text mittig, um anzuzeigen, dass keine Daten in Liste liegen
.keine-daten-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  text-align: center;
  opacity: 70%;
}

mat-toolbar {
  h1 {
    font-size: larger !important;
  }

  h2 {
    font-size: large !important;
  }

  h3 {
    font-size: medium !important;
  }

  h4 {
    font-size: small !important;
  }

  h5 {
    font-size: smaller !important;
  }
}

// Zum Anzeigen des DateTime Pickers in einem matInput vom typ datetime-local
.mdc-text-field__input::-webkit-calendar-picker-indicator {
  display: block !important;
  cursor: pointer;
}
