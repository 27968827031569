@use "SASS:map";
@use "@angular/material" as mat;

@mixin color($theme, $isDarkTheme) {
  $color-config: mat.m2-get-color-config($theme);
  $accent-palette: map.get($color-config, "accent");
  $primary-palette: map.get($color-config, "primary");

  .button:hover {
    background-color: mat.m2-get-color-from-palette($primary-palette, "lighter") !important;
    cursor: pointer;
  }
}
