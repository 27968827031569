@use "SASS:map";
@use "@angular/material" as mat;

@mixin color($theme, $isDarkTheme) {
  $color-config: mat.m2-get-color-config($theme);
  $accent-palette: map.get($color-config, "accent");
  $primary-palette: map.get($color-config, "primary");
  @if $isDarkTheme {
    span {
      color: white;
    }

    .links {
      color: white;
    }

    .rechts {
      color: white;
    }

    .unten {
      color: white;
    }
  }
}
