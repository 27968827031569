@use "SASS:map";
@use "@angular/material" as mat;

@use "_list-theme" as list;
@use "_button-toggle-theme" as button-toggle;
@use "_link-theme" as link;
@use "src/app/meldungen/meldung-details/_meldung-details.component-theme" as meldung-details;
@use "src/app/meldungen/meldung-overview/_meldung-overview.component-theme" as meldung-overview;
@use "src/app/lagedarstellung/lagedarstellung-window-tabs/_lagedarstellung-window-tabs.component-theme" as
  lagedarstellung;
@use "src/app/navigation/application-toolbar/_application-toolbar.component-theme" as application-toolbar;
@use "src/app/lagedarstellung/lagen/lage-infobar/_lage-infobar.component-theme" as lage-infobar;
@use "src/app/lagedarstellung/base-map/zeichen-leiste/_zeichen-leiste.component-theme.scss" as zeichen-leiste;
@use "src/app/lagedarstellung/fuehrungsebene-hierarchie/fuehrungsebene-hierarchie-tree/_fuehrungsebene-hierarchie-tree.component-theme.scss"
  as fuehrungsebene-hierarchie-tree;
@use "src/app/auth/program-select/_program-select.component-theme.scss" as program-select;
@use "/src/app/lagedarstellung/taktische-zeichen/personenschaden/personenschaden-popup/_personenschaden-popup.component-theme.scss"
  as personenschaden-popup;
@use "src/app/shared/components/icon-and-text/_icon-and-text.component-theme.scss" as icon-and-text;
@use "src/app/lagedarstellung/auftrag/auftrag-header-element/_auftrag-header-element.component-theme.scss" as
  auftrag-header-element;
/**
 * Zusammenfassung aller Farbanpassungen für Components, um diese einfach in den verschiedenen Themes zu nutzen.
 * - Farbanpassungen für allgemeinte Components, z.B. Table, Buttons usw. landen in diesem Orner.
 * - Farbanpassungen für Module-Components sollten neben die jeweilige Component ins Module gelegt werden.
 */
@mixin color($theme, $isDarkTheme) {
  // Farbanpassungen für allgemeine Components hier einbinden...
  @include list.color($theme, $isDarkTheme: $isDarkTheme);
  @include button-toggle.color($theme, $isDarkTheme: $isDarkTheme);
  @include link.color($theme, $isDarkTheme: $isDarkTheme);

  // Farbanpassungen für Module-Components hier einbinden...
  @include meldung-details.color($theme, $isDarkTheme: $isDarkTheme);
  @include meldung-overview.color($theme, $isDarkTheme: $isDarkTheme);
  @include lagedarstellung.color($theme, $isDarkTheme: $isDarkTheme);
  @include application-toolbar.color($theme, $isDarkTheme: $isDarkTheme);
  @include lage-infobar.color($theme, $isDarkTheme: $isDarkTheme);
  @include zeichen-leiste.color($theme, $isDarkTheme: $isDarkTheme);
  @include fuehrungsebene-hierarchie-tree.color($theme, $isDarkTheme: $isDarkTheme);
  @include program-select.color($theme, $isDarkTheme: $isDarkTheme);
  @include personenschaden-popup.color($theme, $isDarkTheme: $isDarkTheme);
  @include icon-and-text.color($theme, $isDarkTheme: $isDarkTheme);
  @include auftrag-header-element.color($theme, $isDarkTheme: $isDarkTheme);

  $color-config: mat.m2-get-color-config($theme);
  $accent-palette: map.get($color-config, "accent");
  $primary-palette: map.get($color-config, "primary");
  .leaflet-popup-content-wrapper {
    background-color: mat.m2-get-color-from-palette($primary-palette, "lighter") !important;
  }

  .leaflet-popup-tip {
    background-color: mat.m2-get-color-from-palette($primary-palette, "lighter") !important;
  }
}
