@use "SASS:map";
@use "@angular/material" as mat;

@mixin color($theme, $isDarkTheme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, "primary");
  $light: mat.m2-get-color-from-palette($primary-palette, "light");
  $dark: mat.m2-get-color-from-palette($primary-palette, "dark");
  $light-contrast: mat.m2-get-color-from-palette($primary-palette, "light-contrast");
  $dark-contrast: mat.m2-get-color-from-palette($primary-palette, "dark-contrast");

  .tree-node-row {
    @if $isDarkTheme {
      background-color: $dark !important;
      box-shadow: 1px 1px 5px rgba(black, 0.5);
    } @else {
      background-color: $light !important;
      box-shadow: 1px 1px 3px rgba(black, 0.2);
    }
  }
}
