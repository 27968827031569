@use "SASS:map";
@use "@angular/material" as mat;

@mixin color($theme, $isDarkTheme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, "primary");

  .list-row:hover:not(.selected-list-row) {
    background-color: mat.m2-get-color-from-palette($primary-palette, "lighter") !important;
  }

  .selected-list-row {
    background-color: mat.m2-get-color-from-palette($primary-palette) !important;
  }

  .selected-list-row mat-chip {
    --mdc-chip-label-text-color: white;
    background-color: mat.m2-get-color-from-palette($primary-palette, "darker") !important;
  }
}
