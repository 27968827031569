@use "SASS:map";
@use "@angular/material" as mat;

@mixin color($theme, $isDarkTheme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, "primary");
  $accent-palette: map.get($color-config, "accent");

  a {
    @if $isDarkTheme {
      color: #187bb4;
    }
  }
}
