@use "SASS:map";
@use "@angular/material" as mat;

@mixin color($theme, $isDarkTheme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, "primary");
  $accent-palette: map.get($color-config, "accent");

  .mat-button-toggle-checked {
    @if $isDarkTheme {
      color: mat.m2-get-color-from-palette($accent-palette, "darker") !important;
      background-color: mat.m2-get-color-from-palette($primary-palette) !important;
    } @else {
      color: mat.m2-get-color-from-palette($accent-palette) !important;
    }
  }
}
