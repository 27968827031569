@use "SASS:map";
@use "@angular/material" as mat;

@mixin color($theme, $isDarkTheme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, "primary");

  .draft .mdc-data-table__cell {
    @if $isDarkTheme {
      color: mat.m2-get-color-from-palette($primary-palette, 400);
    } @else {
      color: mat.m2-get-color-from-palette($primary-palette, 500);
    }
  }
}
